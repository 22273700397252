//LIBS

//=include ./libs/jquery-1.11.1.min.js
//=include ./libs/slick.min.js
//=include ./libs/jquery.colorbox.js
//=include ./libs/toastr.js

//=include ./functions.js
//=include ./defaults.js

//COMPONENTS

//=include ./components/index/index_mainSlider.js
//=include ./components/index/index_popularCategoriesSlider.js
//=include ./components/index/index_productsAtDiscountSlider.js
//=include ./components/index/index_newProuctsBlock.js
//=include ./components/index/index_popularBrands.js
//=include ./components/index/index_benefitsBlock.js
//=include ./components/index/index_teamSlider.js


//=include ./components/cart/cart_tabs.js


//=include ./components/productCard.js


//=include ./scripts.js
(function ($) {
    //on load
    $(window).on('load', function () {
        /*** open mobile menu on click***/
        $(".mobileMenu .openMobileMenuBtn").click(function(){
            $(this).toggleClass("active");
            $(".navMenu").toggleClass("active");
            $('body').toggleClass("removescroll");
            $('.profileContainerOnHeader').toggleClass('hide');
        });
        /*** subcategoryContainer close ****/
        $(document).on("click", function(e) {
            if (!$("header .navMenu .category").has(e.target).length && !$("header .navMenu .category").is(e.target)) {
                $("header .navMenu .category").removeClass("active");
            }
        });

        /*** subcategoryContainer open on click ****/
        $("header .navMenu .category ").click(function(e){
            $(".subcategories-container").not($(this).siblings(".subcategories-container")).removeClass("active");
            $(this).toggleClass("active").siblings(".subcategories-container").toggleClass("active");
            e.stopPropagation();
        });

        const cboxOptionsMobile = {
            width: '100%',
            //height: '100%',
            opacity: 0.9,
            title: function () {
                return($(this).attr('title'));
            },
            photo: true,
            rel: true,
            preloading: true,
            closeButton: true,
            close: 'close',
        };

        const cboxOptionsDesk = {
            opacity: 0.9,
            title: function () {
                return($(this).attr('title'));
            },
            photo: true,
            rel: true,
            preloading: true,
            closeButton: true,
            close: 'close',
        };

        cboxOptions = $(window).width() > 1024 ? cboxOptionsDesk : cboxOptionsMobile;

        $('a.gallery').colorbox(
             cboxOptions
        );

        // $('a.gallery').colorbox({
        //      //width: 900,
        //     // height: 900,
        //     // maxWidth: 500,
        //     // maxHeight: 500,
        //     // innerWidth: 500,
        //     // innerHeight: 500,
        //     // width: 500,
        //     // height: 500,
        //     // scalePhotos: true,
        //     opacity: 0.9,
        //     title: function () {
        //         return($(this).attr('title'));
        //     },
        //     photo: true,
        //     rel: true,
        //     preloading: true,
        //     closeButton: true,
        //     close: 'close',
        //     resize: true
        //
        // });


        //
        // jQuery('.a.gallery').colorbox(cboxOptions);
        //
        $(window).resize(function(){
            $.colorbox.resize({
                width: window.innerWidth > parseInt(cboxOptions.maxWidth) ? cboxOptions.maxWidth : cboxOptions.width,
                height: window.innerHeight > parseInt(cboxOptions.maxHeight) ? cboxOptions.maxHeight : cboxOptions.height
            });
        });
    });




    // if( $(window).width() < 1024 ){
    //     $('.benefits-containers').slick({
    //         infinite: true,
    //         dots: true,
    //         arrows: false,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         responsive: [
    //             {
    //                 breakpoint: 1201,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 2,
    //                 }
    //             },
    //             {
    //                 breakpoint: 1024,
    //                 settings: {
    //                     slidesToShow: 2,
    //                     slidesToScroll: 2
    //                 }
    //             },
    //             {
    //                 breakpoint: 769,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             },
    //             {
    //                 breakpoint: 380,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //         ]
    //     });
    //
    //
    //     $('.flex-col-benefit').slick({
    //         dots: true,
    //         infinite: true,
    //         speed: 300,
    //         slidesToShow: 1,
    //         centerMode: true,
    //         variableWidth: true,
    //         responsive: [
    //             {
    //                 breakpoint: 1200,
    //                 settings: {
    //                     arrows: false,
    //                     centerMode: true,
    //                    // centerPadding: '40px',
    //                     slidesToShow: 2
    //                 }
    //             },
    //             {
    //                 breakpoint: 480,
    //                 settings: {
    //                     arrows: false,
    //                     centerMode: true,
    //                    // centerPadding: '40px',
    //                     slidesToShow: 1
    //                 }
    //             }
    //         ]
    //     });
    //
    //     $('.slider-latest-news .container-latest-news').slick({
    //         infinite: true,
    //         dots: true,
    //         arrows: false,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         responsive: [
    //
    //             {
    //                 breakpoint: 1024,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             },
    //             {
    //                 breakpoint: 380,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //         ]
    //     });
    //
    //     $('.slick-slider-grid .flex-col-grid').slick({
    //         infinite: true,
    //         dots: true,
    //         arrows: false,
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         responsive: [
    //
    //             {
    //                 breakpoint: 1024,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             },
    //             {
    //                 breakpoint: 380,
    //                 settings: {
    //                     slidesToShow: 1,
    //                     slidesToScroll: 1
    //                 }
    //             }
    //         ]
    //     });
    // }



//scroll top
        var top_show = 250;
        var delay = 1000;
        $(document).ready(function () {
            $(window).scroll(function () {
                if ($(this).scrollTop() > top_show) $('#top').fadeIn();
                else $('#top').fadeOut();
            });
            $('#top').click(function () {
                $('body, html').animate({
                    scrollTop: 0
                }, delay);
            });
        });

        $("#gallery_load_more").click(function (){
            let url = $(this).data('url');
            let count = $("#gallery_load_more").attr('data-count');
            $.ajax({
                url: url,
                data: {count:count},
                dataType:'json',
                success: function(res){
                    console.log(res);
                    if(res.status)
                    {
                        $("#gallery_container").append($(res.html).show('slow'));
                        $("#gallery_load_more").attr('data-count', res.count);
                    }
                }
            });
        });

    $(".container-cookies .close").on('click', function () {

        $('.container-cookies').removeClass('active');

        let date = new Date(Date.now() + 86400e3);
        date = date.toUTCString();//1 day
        document.cookie = "cookie_notify_seen=true; expires=" + date;
        console.log(document.cookie)
    });











})(jQuery);